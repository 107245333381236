<template>
  <el-drawer title="订阅组成员" :visible.sync="dialog" direction="rtl" size="25%" :append-to-body="true"
    style="font-size: 20px; font-weight: 600">
    <div class="grouplist" style="margin-bottom: 100px">
      <h2 style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-bottom: 1px solid #ccc;
          margin: 0;
          padding: 0 8px 8px 8px;
        "></h2>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="cCusName" label="公司名称" width="340"> </el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" class="linkmargin" :disabled="scope.row.count > 0"
              @click="handleDel(scope.row)">取消订阅</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    }">
      <el-button @click="dialog = false">取消</el-button>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      timer: null,
      tableData: [],
      Cid: "",
    };
  },
  methods: {
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("确定要提交表单吗？")
        .then((_) => {
          this.loading = true;
          this.timer = setTimeout(() => {
            done();
            // 动画关闭需要一定的时间
            setTimeout(() => {
              this.loading = false;
            }, 400);
          }, 2000);
        })
        .catch((_) => { });
    },

    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    openForm(Cid) {
      this.dialog = true;
      console.log(Cid);
      this.Cid = Cid;

      this.$http
        .post("/BO/CustomerinfoGroup/GetCustmerinfoDetailDtoList", {
          Search: {
            Keyword: Cid,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.tableData = res.Data;
            console.log(this.tableData);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 删除
    handleDel(row) {
      this.$confirm("此操作将取消当前订阅,确认继续吗", "提示", {
        type: "warning",
        callback: (val) => {
          if (val == "confirm") {
            this.$http
              .post("/BO/CustmerinfoDetail/DeleteData", [row.Id])
              .then((res) => {
                if (res.Success) {
                  this.openForm(this.Cid);
                  this.$message.success("操作成功");
                } else {
                  this.$message.error(res.Msg);
                }
              });
          }
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iconStyle {
  color: #409eff;
}

.form {
  position: relative;
  padding-right: 10px;
}

.el-form-item {
  margin-bottom: 22px;
  margin-left: 13px;
}
</style>